import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { RequestorMapping } from '../../../common/commons';
import RowMenuCell from '../grid/RowEditActions';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';
import DataGrid from '../grid';
import RequestTypeWrapper from './RequestTypeWrapper';
import Papa from 'papaparse';
import moment from 'moment';
import { getGridStringOperators } from '@mui/x-data-grid';
import uniq from 'lodash/uniq';
import { renderEditSingleSelectCell } from '../grid/EditSingleSelectCell';


const Requestor = [
    RequestorMapping.B2B,
    RequestorMapping.CUSTOMER,
    RequestorMapping.EMPLOYEE,
    RequestorMapping.TCICUSTOMER,
   "survivor",
    'upstream',
];
const RequestTypeScopeValues = ['All', 'California Only', 'Non-California Only', 'Not Applicable'];

const COLUMNS_UPStream = [{
    name: "#",
    mapping: "filteredRowIndex",
    width: 40,
    disableColumnMenu: true,
    type: 'singleSelect',
    customSingleSelectFilter: true,
}, {
    name: "App Name",
    mapping: "display",
    displayTootip: ["display"],
    customSingleSelectFilter: true,
    width: 120,
    // flex: 1,
    type: 'singleSelect',
},
{
    name: "Requestor",
    mapping: "requestor",
    width: 160,
    valueOptions: Object.values(RequestorMapping),
    customSingleSelectFilter: true,
    type: 'singleSelect',
},
{
    name: "Routing Automated",
    mapping: "routing_automated",
    width: 160,
    valueOptions: ["Request Automated", "Response Automated"],
    customSingleSelectFilter: true,
    type: 'singleSelect',
}, {
    name: "Right to Correct",
    mapping: "rtc_scope",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,
}, {
    name: "Right to Delete",
    mapping: "rtd_scope",
    editable: true,
    width: 150,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,
}, {
    name: "Right to Know",
    mapping: "rtk_scope",
    editable: true,
    width: 150,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,
}, {
    name: "Right to OptOut",
    mapping: "rtoo_scope",
    editable: true,
    width: 150,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,
}, {
    name: "Edit",
    mapping: "actions",
    adminEnabled: true,
    width: 80,
    editable: false,
    disableColumnMenu: true,
    renderCell: RowMenuCell
}];

const useStyles = makeStyles(() => ({
    appscopeWrapper: {
        marginTop: '4px',
        marginRight: '15px',
        // Custom Class for the Row When the Row has in_active = true
        '& .isInactive-row-cust-cls': {
            color: 'red'
        },
    },
    additionalRules: {
        marginTop: '10px',
        padding: '0px 0px 10px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        }
    },
    toolbar: {
        textAlign: "right",
        height: '35px',
        marginRight: '15px',
        '& .MuiButtonBase-root': {
            padding: '5px !important'
        }
    }
}));
function AppScope({ adminActiveTab, fromredirection }) {

    const [appsData, setAppsData] = useState([]);
    const [additionalRules, setAdditionalRules] = useState("");
    const [selectionModel, setSelectionModel] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const requestor = Requestor[adminActiveTab];
    const context = useContext(AppContext)
    const authToken = context.authToken.get

    const classes = useStyles()
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, adminActiveTab])
    const getDownStreamColumns = (gridData) => {
        return [{
            name: "#",
            mapping: "filteredRowIndex",
            width: 40,
            disableColumnMenu: true,

        }, {
            name: "Application Name",
            mapping: "display",
            valueOptions: gridData?.map(({ display }) => display),
            customSingleSelectFilter: true,
            displayTootip: ["display"],
            width: 185,
            type: 'singleSelect',
        },{
            name: "Tier",
            mapping: "tier",
            valueOptions: [...new Set(gridData?.map(({ tier }) => tier))],
            editable: false,
            width: 100,
            type: 'singleSelect',
            customSingleSelectFilter:true
        },
        {
            name: "Integration Pattern",
            mapping: "integration_pattern",
            width: 180,
            type: 'singleSelect',
            valueOptions: ['API Pull', 'API Push', 'FTP Push', 'Jira Push'],
            customSingleSelectFilter: true
        }, {
            name: "Request Json",
            mapping: "request_json",
            width: 180,
            type: 'singleSelect',
            isFTPNonEditable: true,
            editable: true,
            getValueOptions : (row)=>{
                if(row.integration_pattern === "Jira Push") {
                    return ['CPS Json', 'Non-PI Json', 'Not Applicable', 'Upstream Json']
                }
                return  ['CPS Json', 'Non-PI Json', 'Upstream Json']
            },
            renderEditCell : renderEditSingleSelectCell,
            // valueOptions:,
            filterOptions: ['CPS Json', 'Non-PI Json', 'Not Applicable', 'Upstream Json'],
            customSingleSelectFilter: true
        }, {
            name: "Request Automated",
            mapping: "is_request_automatedText",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: ['Yes', 'No'],
            customSingleSelectFilter: true
        }, {
            name: "Right to Correct",
            mapping: "rtc_scope",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: RequestTypeScopeValues,
            customSingleSelectFilter: true
        }, {
            name: "Right to Delete",
            mapping: "rtd_scope",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: RequestTypeScopeValues,
            customSingleSelectFilter: true
        }, {
            name: "Right to Know",
            mapping: "rtk_scope",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: RequestTypeScopeValues,
            customSingleSelectFilter: true
        }, {
            name: "Right to OptOut",
            mapping: "rtoo_scope",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: RequestTypeScopeValues,
            customSingleSelectFilter: true
        }, {
            name: "Is Active",
            mapping: "is_active",
            editable: true,
            type: 'singleSelect',
            valueOptions: ['True', 'False'],
            customSingleSelectFilter: true,
            width: '125'
        }, {
            name: "Edit",
            mapping: "actions",
            adminEnabled: true,
            width: 80,
            editable: false,
            renderCell: RowMenuCell,
            disableColumnMenu: true,

        }];
    }
    const getSurvivorColumns = (gridData) => {
        return [{
            name: "#",
            mapping: "filteredRowIndex",
            width: 40,
            disableColumnMenu: true,

        }, {
            name: "Application Name",
            mapping: "display",
            valueOptions: gridData?.map(({ display }) => display),
            customSingleSelectFilter: true,
            displayTootip: ["display"],
            width: 185,
            type: 'singleSelect',
        },{
            name: "Tier",
            mapping: "tier",
            valueOptions: [...new Set(gridData?.map(({ tier }) => tier))],
            editable: false,
            width: 100,
            type: 'singleSelect',
            customSingleSelectFilter:true
        },
        {
            name: "Integration Pattern",
            mapping: "integration_pattern",
            width: 180,
            type: 'singleSelect',
            valueOptions: ['API Pull', 'API Push', 'FTP Push', 'Jira Push'],
            customSingleSelectFilter: true
        }, {
            name: "Request Json",
            mapping: "request_json",
            width: 180,
            type: 'singleSelect',
            isFTPNonEditable: true,
            editable: true,
            getValueOptions : (row)=>{
                if(row.integration_pattern === "Jira Push") {
                    return ['CPS Json', 'Non-PI Json', 'Not Applicable', 'Upstream Json']
                }
                return  ['CPS Json', 'Non-PI Json', 'Upstream Json']
            },
            renderEditCell : renderEditSingleSelectCell,
            // valueOptions:,
            filterOptions: ['CPS Json', 'Non-PI Json', 'Not Applicable', 'Upstream Json'],
            customSingleSelectFilter: true
        }, {
            name: "Request Automated",
            mapping: "is_request_automatedText",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: ['Yes', 'No'],
            customSingleSelectFilter: true
        }, {
            name: "Right to Create",
            mapping: "rtcr_scope",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: RequestTypeScopeValues,
            customSingleSelectFilter: true
        }, {
            name: "Right to Terminate",
            mapping: "rtt_scope",
            editable: true,
            width: 180,
            type: 'singleSelect',
            valueOptions: RequestTypeScopeValues,
            customSingleSelectFilter: true
        }, {
            name: "Is Active",
            mapping: "is_active",
            editable: true,
            type: 'singleSelect',
            valueOptions: ['True', 'False'],
            customSingleSelectFilter: true,
            width: '125'
        }, {
            name: "Edit",
            mapping: "actions",
            adminEnabled: true,
            width: 80,
            editable: false,
            renderCell: RowMenuCell,
            disableColumnMenu: true,

        }];
    }
    /**
     * Method returns a unique appId Based on the Active Tab
     * For Customer, Employee,B2b and TCI Customer 
     *  The Unique combination is app_id + requestor (based on Active Tab)
     * For Upstreams there is case where app_id can be duplicated due to this 
     *      The Unique combination is app_id + requestor (based on each Row from the response)
     * 
     * @param {app Details} app 
     * @returns AppId
     */
    const getuniqueAppId = (app) => {
        let isBasedOnType = false;
        if (requestor === "upstream") {
            isBasedOnType = true;
        }
        return isBasedOnType ? app.id + app.requestor : app.id + requestor;

    }
    const fetchData = () => {
        setIsProcessing(true);
        setAppsData([])
        let params = {
            requestor
        }
        const url = "apps";
        if (requestor === "upstream") {
            params = {
                type: requestor
            }
        }
        axios.get(url, {
            headers: {
                Authorization: authToken
            },
            params
        }).then(res => {
            let selection = [];
            let addit_rule = "";
            if (res.data.length > 0) {
                selection = [getuniqueAppId(res.data[0])];
                addit_rule = res.data[0].additional_rules || "None";
            }
            setAppsData(res.data)
            setAdditionalRules(addit_rule)
            setSelectionModel(selection);
            setIsProcessing(false);
        }).catch(err => {
            setIsProcessing(false);
            console.log(err)
        })
    }
    const updateDownstreamApps = (rowInfo) => {
        setIsProcessing(true)
        axios.post(`apps`, {
            app_id: rowInfo.originalId,
            is_request_automated: rowInfo.is_request_automatedText === "Yes",
            is_response_automated: rowInfo.is_response_automatedText === "Yes",
            rtc_scope: rowInfo.rtc_scope,
            rtcr_scope: rowInfo.rtcr_scope,
            rtt_scope: rowInfo.rtt_scope,
            rtd_scope: rowInfo.rtd_scope,
            rtk_scope: rowInfo.rtk_scope,
            rtoo_scope: rowInfo.rtoo_scope,
            requestor: rowInfo.requestor,
            is_active: rowInfo.is_active === "True",
            request_json: rowInfo.request_json
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData()
            }).catch(err => {
                setIsProcessing(false)
                console.log(err)
            })
    }
    const updateUpStreamApps = (rowInfo) => {
        setIsProcessing(true)
        axios.put(`apps/upstream/${rowInfo.originalId}`, {
            rtc_scope: rowInfo.rtc_scope,
            rtd_scope: rowInfo.rtd_scope,
            rtk_scope: rowInfo.rtk_scope,
            rtoo_scope: rowInfo.rtoo_scope
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData()
            }).catch(err => {
                console.log(err)
                setIsProcessing(false)
            })
    }
    const updateApp = (rowInfo) => {
        if (requestor === "upstream") {
            updateUpStreamApps(rowInfo);
        } else {
            updateDownstreamApps(rowInfo);
        }

    }
    const dataGridColumns = (gridData) => {
        if (requestor === "upstream") {
            return COLUMNS_UPStream?.map((upstream_column) => {
                if (upstream_column.customSingleSelectFilter && upstream_column.mapping == "display") {
                    upstream_column.valueOptions = uniq(gridData?.map(({ display }) => display))
                }
                return {
                    ...upstream_column,
                }
            });
        }
        if(requestor ==="survivor"){
            return getSurvivorColumns(gridData);
        
        }
        return getDownStreamColumns(gridData);
    }
    const isAdditionalRulesVisible = () => {
        return requestor !== "upstream";
    }
    const appsGridData = () => {
        let isBasedOnType = false;
        if (requestor === "upstream") {
            isBasedOnType = true;
        }
        return appsData?.map((row, i) => {
            let formatedScope = {}
            if (isBasedOnType) {
                formatedScope = {
                    rtk_scope: row.rtk_scope ? 'Yes' : 'No',
                    rtd_scope: row.rtd_scope ? 'Yes' : 'No',
                    rtoo_scope: row.rtoo_scope ? 'Yes' : 'No',
                    rtc_scope: row.rtc_scope ? 'Yes' : 'No',
                }
            }
            return {
                rowId: (i + 1),
                filteredRowIndex: (i + 1),
                is_request_automatedText: row.is_request_automated ? "Yes" : "No",
                is_response_automatedText: row.is_response_automated ? "Yes" : "No",
                ...row,
                id: getuniqueAppId(row),
                originalId: row.id,
                is_active: row.is_active ? "True" : "False",
                routing_automated: row.comm_type === "REQUEST" ? "Request Automated" : "Response Automated",
                ...formatedScope
            }
        })
    }
    const downloadAppScopeData = (rows) => {
        const downloadLink = document.createElement("a");
        let columnsData =[]
        if(requestor ==="survivor"){
            columnsData = getSurvivorColumns([])
        }else{
            columnsData = getDownStreamColumns([])
    }
        const formatedReportData = rows?.map((record) => {
            const formatedRecord = {};
          
        columnsData.forEach(element => {
            if (element.name !== "#" && element.name !== "Edit") {
                let valueFormated = record[element.mapping];
                if (["is_request_automatedText", "is_response_automatedText"].includes(element.mapping)) {
                    valueFormated = valueFormated ? "Yes" : "No"
                }
                formatedRecord[element.name] = valueFormated;
            }
        });
            return formatedRecord;
        });
        const csv = Papa.unparse(formatedReportData)
        const blob = new Blob(["\ufeff", csv])
        const url = URL.createObjectURL(blob)
        downloadLink.href = url
        // Formating the CSV File Name
        const timestamp = moment().format("YYYYMMDDHHmm")
        downloadLink.download = `App scope_${requestor}_${timestamp}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }
    const appscopeGridData = appsGridData();
    /**
     * Returns Empty when the requestor type is upstream
     * and returns is_active payload for other requestors
     * @returns Default Filters that are to be applied to the data Grid
     */
    const applyDefaultFilters = () => {
        if (requestor === "upstream") {
            return {}
        }
        return {
            defaultFilterOrder: ["is_active"],
            defaultFilters: {
                "is_active": {
                    "columnField": "is_active",
                    "id": 435,
                    "operatorValue": "equals",
                    "value": "True",
                    "columnInfo": {
                        "filterable": true,
                        "type": "singleSelect",
                        "align": "left",
                        "filterOperators": getGridStringOperators().filter((operator) => {
                            return operator.value === "equals";
                        }),
                        "field": "is_active",
                        "name": "Is Active",
                        "mapping": "is_active",
                        "valueOptions": [
                            "True",
                            "False"
                        ],
                        "customSingleSelectFilter": true,
                    }
                }
            }
        }
    }
    return (
        <div className={classes.appscopeWrapper}>
            {isProcessing && (<LoadingIndicator />)}
            <DataGrid
                toolbarConfig={requestor !== "upstream" ? {
                    downloadLabel: "Download",
                    handleNoRowDownloadDisabled: true,
                    handleDownloadGrid: downloadAppScopeData
                } : null}
                disableSelectionOnClick={false}
                enableCustomFiltering={true}
                key={requestor}
                columns={dataGridColumns(appscopeGridData)}
                selectionModel={selectionModel}
                clearFilterSortState={requestor + fromredirection}
                getRowClassName={(params) => {
                    const _classes = [];
                    // For Displaying the Custom Class for the IsActive Row
                    if (params?.row?.is_active === "False" && requestor !== "upstream") {
                        _classes.push("isInactive-row-cust-cls");
                    }
                    return _classes.join(" ");
                }}
                isCellEditable={(cellParams) => {
                    // Disabling the Edit Functionaly only for FTP Records.
                    if (cellParams.colDef.isFTPNonEditable) {
                        return cellParams.row.integration_pattern !== "FTP Push";
                    }
                    return cellParams.colDef.editable;
                }}
                {...applyDefaultFilters()}
                onSelectionModelChange={(selection) => {
                    !isProcessing && setSelectionModel(selection);
                    const selectedAppData = appsData.find((app) => {
                        return getuniqueAppId(app) === selection[0]
                    })
                    !isProcessing && setAdditionalRules(selectedAppData?.additional_rules || "None");
                }}
                rows={appscopeGridData}
                onRowEditCommit={(rows, event) => {
                    if (event.rowInfo) {
                        updateApp(event.rowInfo);
                    }
                }}
            />
            {isAdditionalRulesVisible() && (<div className={classes.additionalRules}>
                <div className={"header"}>
                    Application Additional Rules
                </div>
                <div>
                    {additionalRules}
                </div>
            </div>)}

        </div>
    )
}
export default React.memo(AppScope);

const AppScopeWrapper = (parentprops) => {
    return (<RequestTypeWrapper
        title={"APPS SCOPE"}
        routePath={"appsscope"}
        additionalTab={[{
            name: "survivor",
            display: "survivor"
        },{
            name: "upstreamapps",
            display: "Upstream Apps"
        }]}
        renderTabContent={(props) => {
            return <AppScope
                adminActiveTab={props.activeTab}
                {...props} />
        }}
        {...parentprops}
    />)
}
export const AppScopeTab = React.memo(AppScopeWrapper);