import { Box, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import ListOfItems from '../ListOfItems';
import axios from '../../../../common/AxiosConfig';
import ResponseJsonContent from './ResponseJsonContent';
import { getFormatedDate } from '../../../../common/commons';
import DeleteDialog from '../../admin/settings/DeleteDialog';
import DataGrid from '../../grid';

const listOfItemsData = [{
    display: "Automated Upload Attempts",
    mapping: "auto_upload_attempts"
}, {
    display: "Manual Upload Attempts",
    mapping: "manual_upload_attempts",

}, {
    display: "Attachments Placed In FTP",
    tooltip: "ftp_placed_time_local",
    mapping: "ftp_placed_time_utc"
}, {
    display: "Aggregated Response Sent",
    tooltip: "tips_ack_time_local",
    mapping: "tips_ack_time_utc"

}, {
    display: "Acknowledgement Received",
    tooltip: "tips_ack_time_local",
    mapping: "tips_ack_time_utc"

}]
const useStyles = makeStyles(() => ({
    root: {
        '& .MuiButtonBase-root': {
            padding: '5px !important'
        },
        '& .customizeIconButton': {
            width: '27px',
            height: '27px'
        },
        '& .customLink': {
            textDecoration: "underline",
        },
        '& .customselectInput .MuiSelect-root': {
            paddingTop: '10.5px',
            paddingBottom: '10.5px'

        }
    },
    "attachmentsWrapper": {
        marginLeft: "10px",
        marginRight: "10px",
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
    }
}));


const ConsolidatedResponse = ({ tabInfo, setDisplayErrMsg, setLoading, handleNewTabOpen, reloadInformation }) => {
    const context = useContext(AppContext);
    const authToken = context.authToken.get;
    const classes = useStyles();

    const [responseData, setResponseData] = useState({});
    const [openReuploadConfirmation, setOpenReuploadConfirmation] = useState(false);

    useEffect(() => {
        fetchData();
    }, [tabInfo.tabtype, tabInfo.app_id]);

    const fetchData = () => {
        setLoading(true);
        return axios.get(`ticket/${tabInfo.dsarRequestId}/complete-response`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setLoading(false);
            const responseData = res.data;
            let ftp_placed_time_local = getFormatedDate(responseData.tips?.ftp_placed_time);
            let ftp_placed_time_utc = getFormatedDate(responseData.tips?.ftp_placed_time, true);
            if (responseData.system_attachments.length === 0) {
                ftp_placed_time_utc = "No attachments to upload";
                ftp_placed_time_local = ""
            }
            setResponseData({
                ...responseData,
                tips: {
                    ...responseData.tips,
                    ftp_placed_time_local: ftp_placed_time_local,
                    ftp_placed_time_utc: ftp_placed_time_utc,
                    auto_upload_attempts: responseData.tips.auto_upload_attempts || "0",
                    manual_upload_attempts: responseData.tips.manual_upload_attempts || "0",
                    tips_ack_time_local: getFormatedDate(responseData.tips?.tips_ack_time),
                    tips_ack_time_utc: getFormatedDate(responseData.tips?.tips_ack_time, true)
                }

            });
            reloadInformation();
        }).catch(err => {
            setLoading(false);
            if (err?.response?.data?.message) {
                setDisplayErrMsg(err.response.data.message)
            }
        })
    }

    const handleDeleteDialogClose = () => {
        setOpenReuploadConfirmation(false);
    }
    const handleDeleteDialogSubmit = () => {
        setOpenReuploadConfirmation(false);
        setLoading(true);
        axios.post("response/resend_tips", {
            dsar_id: tabInfo.dsarRequestId
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            fetchData();
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            if (err.response?.data) {
                setDisplayErrMsg(err.response.data.message)
            }
        })
    }
    const getAllFilesData = () => {
        let sortedAttachments = responseData?.system_attachments?.sort((a, b) => a.app_name.localeCompare(b.app_name) || a.filename.localeCompare(b.filename)) || [];
        return sortedAttachments.map((attachment, index) => {
            return {
                ...attachment,
                id: index + 1,
            }
        });
    }
    return (<Box className={`${classes.root} requestDetailsWrapper`} width={"100%"} height={"100%"} >
        <ListOfItems
            items={[...listOfItemsData,
            {
                display: "View Response",
                type: "custom",
                renderCustom: () => {
                    return (
                        <a
                            href="void(0)"
                            className='customLink'
                            data-testid={"fileUploadLink"}
                            onClick={(event) => {
                                event.preventDefault();
                                handleNewTabOpen({
                                    "tabname": "DSAR RESPONSE",
                                    "tabtype": "dsarResponse",
                                    "activeSelection": "viewdsarResponse",
                                    closeIndex: 2,
                                })
                            }}
                        >
                            response.json
                        </a>
                    )
                }
            }, {
                display: "Reupload Response",
                type: "button",
                text: "Reupload",
                handleCallback: () => {
                    setOpenReuploadConfirmation(true);
                }
            }]}
            data={responseData?.tips || {}}
        />
        <DeleteDialog
            openDeleteDialog={openReuploadConfirmation}
            title={"Confirmation"}
            handleDeleteDialogClose={handleDeleteDialogClose}
            handleDeleteDialogSubmit={handleDeleteDialogSubmit}
            closeButtonText={"No"}
            submitButtonText={"Yes"}
        >
            Do you really want to re-upload the response to TIPS/QB64?

        </DeleteDialog>
        {responseData?.system_attachments?.length > 0 && (
            <div data-testid={"attachmentsWrapper"} className={classes.attachmentsWrapper}>
                <div className={"header"}>
                    Attachments
                </div>
                <Box padding={"10px"}>
                    <DataGrid
                        customHeight={"auto"}
                        autoHeight={true}
                        themeColors={{
                            headerColumnColor: '#dae3f3',
                            headerColumnfontColor: 'black',
                            rowbackground: '#f2f2f2 !important',
                            cellBorder: '1px solid white',
                            rowOddBackground: '#f8f9fa !important'
                        }}
                        columns={[{
                            name: "#",
                            mapping: "id",
                            width: 50
                        }, {
                            name: "File Name",
                            mapping: "filename",
                            renderCell: (params) => {
                                return (
                                    <a
                                        href="void(0)"
                                        className='customLink'
                                        data-testid={"fileNameLink"}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleNewTabOpen({
                                                "tabname": params.row["filename"],
                                                "tabtype": "word",
                                                "activeSelection": `${params.row["filename"]}-fileViewer`,
                                                closeIndex: 2,
                                                hideregenerateWordDoc: true,
                                                "downloadFileName": params.row["filename"],
                                                "signedUrlEndPoint": `ticket/response/download/${params.row["s3_key"]}`
                                            })
                                        }}
                                    >
                                        {params.row["filename"]}
                                    </a>
                                )
                            }
                        }, {
                            name: "Downstream",
                            width: 120,
                            mapping: "app_name"
                        }]}
                        rows={getAllFilesData()}
                    />
                </Box>
            </div>)}
        <ResponseJsonContent
            responseData={{
                response_json: responseData?.tips?.tips_ack_json,
                fileName: `${tabInfo.dsarRequestId}_Upstream_Ack.json`
            }}
            tabInfo={tabInfo}
        />
    </Box>)
}
export default React.memo(ConsolidatedResponse);

