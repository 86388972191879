import React, { useState, useContext, useEffect } from "react";
import axios from "../../../../common/AxiosConfig";
import moment from "moment";
import ProcessingTimeChart from "./ProcessingTimeChart";
import { AppContext } from "../../../../../RoutesWithAuth";
import { Typography, makeStyles } from "@material-ui/core";
import FormGroup from "../../FormGroup";
import {RequestTypeMapping, RequestorMapping} from '../../../../common/commons';

const useStyles = makeStyles(() => ({
    root : {
        
    },
    formGroupWrapper : {
        width : '900px'
    },
    customInputCls : {
        width : '140px',
    },
    legendText : {
        textAlign: 'end',
        fontStyle: 'italic',
        fontWeight : '600',
        marginBottom : '5px',
        marginRight : '10px'
    }
}));
function AverageProcessingTime(props) {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();

    const today = moment()
    // To Store the Year DropDown Data
    const [metricYears,setMetricYears] = useState([])
    // Below states directly controls the values inside the FormGroup.
    const [year, setYear] = useState(today.format("YYYY"))
    const [requestType, setRequestType] = useState("All")
    const [requestor, setRequestor] = useState("All")
    const [metric, setMetric] = useState("Downstream")
    // Stores the data and are based on the Form Fields
    const [reportData, setReportData] = useState([])
    // Stored the Filter fields this will be updated when there is click on the legend
    const [toFilter, setToFilter] = useState([])
    // Contains the Filtered Data which will be displayed on the Line Chart
    const [filteredReportData, setFilteredReportData] = useState([])
    // Below States are maintained to display params related to the current Data
    // these will be changed only when the API is successful.
    const [requestTypeFooter, setRequestTypeFooter] = useState(null)
    const [yearFooter, setYearFooter] = useState(null)
    const [metricFooter, setMetricFooter] = useState(null)

    useEffect(() => {
        // This UseEffect is used to Trigger the API
        if (authToken) {
            let mappedRequestType = Object.values(RequestTypeMapping);
            let mappedRequestKeys = Object.keys(RequestTypeMapping);
            let mappedRequestor = Object.values(RequestorMapping);
            let mappedRequestorKeys = Object.keys(RequestorMapping);

            axios.get("/reports/avg_processing_time", {
                headers: {
                    Authorization: authToken
                },
                params: {
                    metric: metric === "Downstream" ? null : "uploaded",
                    request_type: requestType !== "All" ? mappedRequestKeys[mappedRequestType?.indexOf(requestType)] : null,
                    requestor: requestor !== "All" ? mappedRequestorKeys[mappedRequestor?.indexOf(requestor)] : null,
                    year: year,
                }
            }).then(res => {
        
                setRequestTypeFooter(requestType === "All" ? "All Requests" : requestType)
                setMetricFooter(metric === "Downstream" ? metric : "Time to Upload")
                setYearFooter(year)
                setReportData(res.data)
                setFilteredReportData(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken, metric, year, requestType, requestor])

    useEffect(() => {
        // Filtering the Data based on the toFilter State Data
        const reportDataCopy = JSON.parse(JSON.stringify(reportData))
        setFilteredReportData(
            reportDataCopy.map(el => {
                if (toFilter.includes(el.id)) {
                    el.data = []
                }
                return el
            })
        )
    }, [toFilter, reportData])

    useEffect(()=>{
        // Setting up the year Drop Down data
        let years = [];
        for(let i=2020; i<=today.format("YYYY") ; i++ ){
            years.push(i);
        }
        setMetricYears(years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return (

        <div className={`${classes.root}`} data-testid="average-processing-time">
            <Typography variant="h6" className={'tabHeader'}>
                AVERAGE PROCESSING TIME 
            </Typography>
            
            <FormGroup 
                formWrapperClassName={classes.formGroupWrapper}
                fields={[{
                    type : 'multiselect',
                    name : 'metric',
                    label : 'Metric',
                    customInputCls : classes.customInputCls,
                    removeCheckBox : true,
                    options : ['Downstream','Time to Upload'],
                    defaultOption : 'Downstream',
                    size : 2,
                },{
                    type : 'multiselect',
                    name : 'year',
                    label : 'Select Year',
                    customInputCls : classes.customInputCls,
                    removeCheckBox : true,
                    options : metricYears,
                    defaultOption : year,
                    size : 2
                    
                },{
                    type : 'multiselect',
                    name : 'requestType',
                    label : 'Request Type',
                    customInputCls : classes.customInputCls,
                    removeCheckBox : true,
                    options : ["All", ...Object.values(RequestTypeMapping)],
                    defaultOption : 'All',
                    size : 2
                },{
                    type : 'multiselect',
                    name : 'requestor',
                    label : 'Requestor',
                    customInputCls : classes.customInputCls,
                    removeCheckBox : true,
                    options : ["All", ...Object.values(RequestorMapping)],
                    defaultOption : 'All',
                    size : 2
                }]}
                disableFormActions = {true}
                customFormUpdates={(formData)=>{
                    // This Method is called when ever there is any change in the form fields
                    setMetric(formData["metric"]);
                    setYear(formData["year"]);
                    setRequestType(formData["requestType"])
                    setRequestor(formData["requestor"])
                }}
            />
            <div className={classes.legendText}>
                Click on legend to toggle display
            </div>
            <div style={{ height: "650px", background : 'white' }}>
                <ProcessingTimeChart
                    data={filteredReportData}
                    toFilter={toFilter}
                    setToFilter={setToFilter} />
            </div>
            {/* Display Data Related params */}
            {metricFooter && (
                <div className="font-italic text-center">
                    Metric: {metricFooter} ({yearFooter})
                </div>)}
            {requestTypeFooter && (
                <div className="font-italic text-center">
                    Request Type: {requestTypeFooter}
                </div>
            )}
        </div>
    );
}

export default AverageProcessingTime;
